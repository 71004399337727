body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /*font-family: roboto;*/
  overflow-x: hidden;
}

h1 {
  font-size: 2.5rem;
}

.nav-link {
  font-size: 1.1rem;
}

.row-color :hover {
  color: #7399d6;
}

.row-color {
  color: #1155bf;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1rem;
}

.text-dark a {
  color: #343a40;
}

.actualites {
  color: #444;
}

.actualites-parent :hover {
  color: #1155bf;
  text-decoration: none;
}

.text-dark a :hover {
  color: rgb(17, 85, 191);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  display: none;
}

/*.navbar a {*/
  /*color: #fff !important;*/
  /*font-size: 2rem !important;*/
/*}*/

.top-background-cover {
  /* Location of the image */
  background-image: url(./statics/accounting.jpg);

  /* Background image is centered vertically and horizontally at all times */
  background-position: top center;

  /* Background image doesn’t tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn’t move when
  the content’s height is greater than the image’s height */
  /*background-attachment: fixed;*/

  /* This is what makes the background image rescale based
  on the container’s size */
  background-size: cover;

  /* Set a background color that will be displayed
  while the background image is loading */
  background-color: #fff;
  height: 60vh;
  padding-bottom: 5vh;
}
.top-background-gradient {
  background-image: linear-gradient(#ffffff00, #ffffffff);
  /*margin-top: 10vh;*/
  height: 60vh;
  width: 100%;
}

.active {
  color: #1155bf !important;
}

@media only screen and (min-width: 600px) {
  .working-girl {
    width: 30%;
    margin-top: -100px;
  }

  .bm-burger-button {
    display: none
  }

  .nav-link {
    display: block;
  }

  .top-background-cover {
    padding-bottom: 10vh;
  }
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.working-girl {
  position: absolute;
  float: right;
  right: 10%;
  margin-top: -50px;
  width: 40%;
  max-width: 400px;
}

.navbar {
  /*padding-top: 15vh;*/
  /*padding-bottom: 10vh;*/
  padding-left: 5%;
  padding-right: 5%;
  background: #0099ff;
}




/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  right: 36px;
  top: 42px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #0055FF;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

